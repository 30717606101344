import React, { useEffect, useRef, useState } from "react";
import { TUFContextDisplayStyle } from "../../common/communication.base";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import { getContentAlign, getFixedColumnStyles, HeaderColumnProps } from "./utils";
import css from "./DataGrid.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";

const K2HeaderColumn = (props: HeaderColumnProps) => {
  const element = useRef<HTMLDivElement>(null);
  const prevTouch = useRef<TouchList>();
  const [translateX, setTranslateX] = useState(0);
  const [isResizing, setIsResizing] = useState(false);
  const translateXRef = useRef(0);

  useEffect(() => {
    resolveContextMenu(element.current, handleContextMenu);
  }, []);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("touchmove", handleMouseMove);
      document.addEventListener("touchend", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    translateXRef.current = translateX;
  }, [translateX]);

  const getOrderInfo = () => {
    let icon = "wui*arrow.down";

    if (props.orderBy > 0) {
      icon = "wui*arrow.up";
    }

    if (props.orderByCount > 1 && Math.abs(props.orderBy) > 0) {
      return (
        <div>
          <K2Img glyphId={icon} vcx={props.vcx} height={19} width={19} strokeColor={props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame3)} />
          <span style={{ fontSize: "70%", marginTop: "20%" }}>{Math.abs(props.orderBy)}</span>
        </div>
      );
    } else if (Math.abs(props.orderBy) > 0) {
      return (
        <div>
          <K2Img glyphId={icon} vcx={props.vcx} height={19} width={19} strokeColor={props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame3)} />
        </div>
      );
    }
    return null;
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!(e.currentTarget.parentElement instanceof HTMLTableCellElement)) return;

    const nodes = Array.from(e.currentTarget.parentElement.parentElement.children).map((node) => node.getBoundingClientRect());
    const draggedColumnIndex = e.currentTarget.parentElement.cellIndex;

    props.setColumns({ sizes: nodes, draggedColumnIndex: draggedColumnIndex });
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    let yPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      yPos = (e as React.DragEvent).clientY;
    } else {
      yPos = (e as React.TouchEvent).changedTouches[0].clientY;
    }

    if (yPos < e.currentTarget.getBoundingClientRect().top) {
      props.deleteColumn();
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLSpanElement> | React.TouchEvent) => {
    if (!isResizable()) return;

    setIsResizing(true);
    document.body.style.cursor = "col-resize";
  };

  const handleMouseMove = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent) {
      setTranslateX((translateX) => translateX + e.movementX / window.devicePixelRatio);

      if (props.onColumnResize && e.movementX !== 0) {
        props.onColumnResize.call(this, props.columnNdx, e.movementX, false);
      }
    } else {
      let movementX: number;

      if (prevTouch.current) {
        movementX = e.touches[0].pageX - prevTouch.current[0].pageX;
      } else {
        movementX = 0;
      }

      setTranslateX((translateX) => translateX + movementX);
      prevTouch.current = e.touches;

      if (props.onColumnResize && movementX !== 0) {
        props.onColumnResize.call(this, props.columnNdx, movementX, false);
      }
    }
  };

  const handleMouseUp = (e: MouseEvent | TouchEvent) => {
    const diff = translateXRef.current;
    setIsResizing(false);
    setTranslateX(0);
    document.body.style.cursor = "";

    if (props.onColumnResize && diff !== 0) {
      props.onColumnResize.call(this, props.columnNdx, diff, true);
    }

    e.preventDefault();
    prevTouch.current = null;
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!props.onColumnClick) return;

    props.onColumnClick.call(this, -1, props.columnNdx, 1, e.shiftKey, e.ctrlKey);
  };

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.onColumnContextMenu) return;

    props.onColumnContextMenu.call(this, -1, props.columnNdx, null);
  };

  function isDraggable() {
    return props.isDraggable && !props.fixed && !(props.isTreeCell && props.columnNdx === 0);
  }

  function isResizable() {
    return props.isResizable;
  }

  if (!props.columnProportion) return <th style={{ background: "inherit" }}></th>;

  return (
    <th
      className={css.dg_header_column + `${props.fixed ? " dg_fixed_col" : ""}`}
      style={{ ...getFixedColumnStyles(props.columnProportion.Width, props.columnOffset), position: props.fixed ? "sticky" : "relative" }}
    >
      <div
        draggable={isDraggable()}
        ref={element}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        style={{
          justifyContent:
            props.columnProportion?.DisplayStyleRT !== TUFContextDisplayStyle.cdsImage ? getContentAlign(props.columnProportion.Alignment) : "center",
        }}
        className="dg_header_column_content"
      >
        <K2TruncateText style={{ whiteSpace: "nowrap", fontWeight: "normal" }}>{props.columnProportion.Caption}</K2TruncateText>
        {getOrderInfo()}
      </div>
      {!props.fixed && (
        <span
          className={`${css.dg_handle}${props.lastColumn ? ` ${css.dg_handle_last_column}` : ""}`}
          style={{ transform: `translateX(${translateX}px)` }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
        >
          {(isResizing || translateX !== 0) && (
            <div style={{ borderRight: `1px solid ${props.vcx.getColor(props.vcx.Data.ColorMap.ContentNormalColorFrg)}` }} />
          )}
        </span>
      )}
    </th>
  );
};

export default K2HeaderColumn;
