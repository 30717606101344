import React, { useEffect, useRef, useState } from "react";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { GanttUserRangeSelect, UpdateInnerGantt } from "../../common/communication.base";
import { NclGantt, NclInnerGantt } from "../../common/components.ncl";
import { updateDataAfterDrop, NoData, initGanttData } from "./utils";
import css from "./Gantt.scss";
import { useServerState } from "../hooks";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import Exporting from "highcharts/modules/exporting";
import draggable from "highcharts/modules/draggable-points";
import patternFill from "highcharts/modules/pattern-fill";
import boost from "highcharts/modules/boost";
import xrange from "highcharts/modules/xrange";
import ReactResizeDetector, { useResizeDetector } from "react-resize-detector";
import { getAttributes } from "../../common/common";
import { __ } from "../../appcontext";
import { K2GanttFooter } from "./K2Gantt";
import { K2BusyIndicator, BusyIndicatorAlign, BusyIndicatorType } from "../BusyIndicator/K2BusyIndicator";

highchartsGantt(Highcharts);
Exporting(Highcharts);
draggable(Highcharts);
patternFill(Highcharts);
boost(Highcharts);
xrange(Highcharts);
interface GOptions extends Highcharts.Options {
  xAxis?: GXAxisOptions | Array<GXAxisOptions>;
}

interface GXAxisOptions extends Highcharts.XAxisOptions {
  dateTimeLabelFormats?: GAxisDateTimeLabelFormatsOptions;
}

interface GAxisDateTimeLabelFormatsOptions extends Highcharts.AxisDateTimeLabelFormatsOptions {
  hour?: GAxisDateTimeLabelFormatsOptionsObject;
  day?: GAxisDateTimeLabelFormatsOptionsObject;
  week?: GAxisDateTimeLabelFormatsOptionsObject;
  month?: GAxisDateTimeLabelFormatsOptionsObject;
}

interface GAxisDateTimeLabelFormatsOptionsObject extends Highcharts.AxisDateTimeLabelFormatsOptionsObject {
  list?: string[];
}

interface DesktopVCX {
  Fake_VCXColorMap: string;
  Fake_VCXZoom: number;
  OwnBackgroundColor: string;
  CapacitiesColor: string;
  ClientZoom: number;
}

export const K2InnerGantt = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclInnerGantt, UpdateInnerGantt, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclInnerGantt
  );
  (window as any).TNclInnerGantt = {
    ...(window as any).TNclInnerGantt,
    updateFragmentDataString, // Pro desktop components, nemá NclGantt takže mu oklikou aktualizuje data
    updateVCX, // Pro desktop components, nemá NclGantt takže mu oklikou aktualizuje data
  };
  const ganttWrapper = useRef<HTMLDivElement>(null);
  const [chartOptions, setChartOptions] = useState<GOptions>();
  const [version, setVersion] = useState("");
  const ganttRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement> }>();
  const clickTimer = useRef(0);
  const clickCount = useRef(0);
  const colors = control.Colors;
  const zoom = useRef(1);
  const disableWheel = useRef(false);
  /** Cache Range Y a X axis před odesláním serveru */
  const userRangeSelect = useRef<GanttUserRangeSelect>({ x1: undefined, x2: undefined, y1: undefined, y2: undefined });
  const userRangeSelectTimer = useRef(0);
  const pointHoverTimer = useRef(0);
  const initNewGantt = useRef(true);
  const prevGanttId = useRef(undefined);
  const restrictKey = useRef("");
  const rangeSelectorButtonType = "all";
  const prevSize = useRef<{ width: number; height: number }>({ width: 0, height: 0 });
  let content: JSX.Element;

  // Po najetí na hint server vrátí hodnotu hintu

  useEffect(() => {
    if (data.GanttLoader == "false") setLoading(false);
    else {
      setLoading(true);
    }
  }, [data.GanttLoader]);

  useEffect(() => {
    updatePointHoverFunction(data.HoverHint);
  }, [data.HoverHintVersion]);

  useEffect(() => {
    updateSelection(data.SelectedList.toJS() as number[]);
  }, [data.SelectedListVersion]);

  useEffect(() => {
    exportToImageFunction();
  }, [data.ExportToImage]);

  useEffect(() => {
    sendResolution();
  }, []);

  useResizeDetector({ targetRef: element, onResize: onResize, refreshRate: 100, refreshMode: "debounce" });
  const mousePosition = useRef({ x: 0, y: 0 });

  function sendResolution() {
    if (ganttRef.current && ganttRef.current.chart) {
      control.ganttResolution(ganttRef.current.chart.plotWidth.toString(), ganttRef.current.chart.plotHeight.toString());
    }
  }

  function setLoading(state: boolean) {
    if (ganttWrapper.current) {
      if (state) {
        ganttWrapper.current.setAttribute("data-loading", "1");
      } else {
        ganttWrapper.current.setAttribute("data-loading", "0");
      }
    }
  }
  function updateVCX(state: DesktopVCX) {
    control.Container.Ncl.Fake_VCXColorMap = state.Fake_VCXColorMap;
    control.Container.Ncl.Fake_VCXZoom = state.Fake_VCXZoom / 100;
    control.Container.Ncl.OwnBackgroundColor = state.OwnBackgroundColor;
    control.Container.Ncl.CapacitiesColor = state.CapacitiesColor;
    control.Container.Ncl.ClientZoom = state.ClientZoom;
  }
  function updateFragmentDataString(data: any) {
    control.Container = new NclGantt({ ...control.Container.Ncl, FrgtData: { ...data } } as any, parent as any, null, null);
    initKeyDownListener();
  }

  //const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  function updateSelection(SelectedList: Array<number>) {
    if (!SelectedList) return;
    //const utcDate1 = new Date();
    //alert(utcDate1.toLocaleString());

    //data.Type;

    //ganttRef?.current?.chart.showLoading("ahoj");

    ganttRef.current?.chart?.series?.map((serie) =>
      serie.data.map((point) => {
        point.selected = false;
        point.setState("normal");
      })
    );

    for (const selectedId of SelectedList) {
      const lPoint: Highcharts.Point = ganttRef?.current?.chart.get(selectedId.toString()) as Highcharts.Point;
      //lPoint.selected = true;
      if (lPoint) {
        //alert("select:" + lPoint.options.id.toString());
        //lPoint.select(true, true); //neudela redraw
        //lPoint.update({ selected: true }, true, false);

        // Pouze pro úsečky, dříve označovalo i kapacity
        if (lPoint.series.type === "gantt" || ("initialType" in lPoint.series && (lPoint.series as any).initialType === "gantt")) {
          lPoint.selected = true;
          lPoint.setState("select");
        }
      }
    }

    //setVersion(data.GanttUniqueIdentifier + new Date().getTime());
    //ganttRef?.current?.chart.redraw();

    //ganttRef?.current?.chart.hideLoading();
  }

  function exportToImageFunction() {
    if (!data.ExportToImage || !ganttRef.current) return;
    let chartOptions: Highcharts.Options;
    let svgString: string;

    //exportingOptions.type = "application/pdf";
    //exportingOptions.filename = 'my-pdf';

    //this.ganttApi.chart.exportChart(exportingOptions, chartOptions);

    //chartOptions = this.ganttApi.chart.options;

    //this.ganttApi.chart.print();

    //alert('ahoj');

    svgString = ganttRef.current.chart.getSVG(chartOptions);

    control.exportImageToString([svgString]);
  }

  function updatePointHoverFunction(Hint: string) {
    if (!ganttRef.current) return;
    const t = 2;

    const tooltip = ganttRef.current?.chart.tooltip as any;
    const vBox = ganttRef.current.container.current;

    tooltip?.label?.attr({ text: Hint }); // undocumented API; https://github.com/highcharts/highcharts/issues/6824#issuecomment-307730886
    tooltip?.label?.attr({
      x: mousePosition.current.x + t,
      y: mousePosition.current.y - tooltip?.label?.height - t,
    });

    // posun tooltipu v pripade presahu mimo okno
    // Horizontálně
    if (tooltip?.label?.x + tooltip?.label?.width > vBox.clientWidth) {
      tooltip?.label?.attr({ x: vBox.clientWidth - tooltip?.label?.width - 20 });
    } else if (tooltip?.label?.x < 0) {
      tooltip?.label?.attr({ x: 0 });
    }

    //Vertikálně
    if (tooltip?.label?.y + tooltip?.label?.height > vBox.clientHeight) {
      tooltip?.label?.attr({ y: vBox.clientHeight - tooltip?.label?.height });
    } else if (tooltip?.label?.y < 0) {
      tooltip?.label?.attr({ y: 0 });
    }
  }

  useEffect(() => {
    control.setMaxRowCount(1);

    Highcharts.setOptions({
      lang: {
        months: [
          __("january"),
          __("february"),
          __("march"),
          __("april"),
          __("may"),
          __("june"),
          __("july"),
          __("august"),
          __("september"),
          __("october"),
          __("november"),
          __("december"),
        ],
        shortMonths: [
          __("januaryShort"),
          __("februaryShort"),
          __("marchShort"),
          __("aprilShort"),
          __("mayShort"),
          __("juneShort"),
          __("julyShort"),
          __("augustShort"),
          __("septemberShort"),
          __("octoberShort"),
          __("novemberShort"),
          __("decemberShort"),
        ],
        weekdays: [__("sunday"), __("monday"), __("tuesday"), __("wednesday"), __("thursday"), __("friday"), __("saturday")],
        shortWeekdays: [
          __("sundayShort"),
          __("mondayShort"),
          __("tuesdayShort"),
          __("wednesdayShort"),
          __("thursdayShort"),
          __("fridayShort"),
          __("saturdayShort"),
        ],
        rangeSelectorFrom: __("rangeSelectorFrom"),
        rangeSelectorTo: __("rangeSelectorTo"),
        downloadCSV: __("download") + " CSV",
        downloadJPEG: __("download") + " JPEG",
        downloadPDF: __("download") + " PDF",
        downloadPNG: __("download") + " PNG",
        downloadSVG: __("download") + " SVG",
        downloadXLS: __("download") + " XLS",
        viewFullscreen: __("viewFullscreen"),
        exitFullscreen: __("exitFullscreen"),
        printChart: __("printChart"),
        loading: __("loading"),
      },
    });

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);

    initKeyDownListener();

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);

      if (control.Container.Ncl.FrgtData.UseRestrictKeys) {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      }
    };
  }, []);

  function initKeyDownListener() {
    if (control.Container?.Ncl?.FrgtData?.UseRestrictKeys) {
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
    }
  }

  function handleMouseMove(e: MouseEvent) {
    mousePosition.current = { x: e.clientX - element.current?.getBoundingClientRect().left, y: e.clientY - element.current?.getBoundingClientRect().top };
  }

  function handleScroll(e: Event) {
    if (!control.Container.Ncl.FrgtData.NavigatorOnTop) return;

    setChartOptions({ navigator: { top: window.scrollY === 0 ? 1 : window.scrollY } as any });
  }

  function getPlotHeight(): number {
    const contentHeight = (25 + 0.5) * zoom.current * control.VCXZoom * (control.Categories?.length + control.YAxisOptions.breaks.length); //Obsah
    return contentHeight;
  }

  function getGanttHeight(): number {
    const height = element.current.getBoundingClientRect().height;

    return height;
  }

  function getMaxRowCount(): number {
    const minRow = Math.floor(ganttRef.current.chart.plotHeight / control.YAxisOptions.staticScale);
    const categoriesLength = control.Categories.length - 1;
    if (minRow > categoriesLength) return categoriesLength;
    return minRow;
  }

  function initGanttOptions() {
    if (!control.Container) return null;
    const ganttOptions: GOptions = {
      boost: {
        enabled: true,
        allowForce: true,
        useGPUTranslations: true,
        seriesThreshold: 0,
        //usePreallocated: true,
      },
      loading: {
        hideDuration: 1000,
        showDuration: 1000,
      },
      chart: {
        backgroundColor: getBgColor(),
        spacing: [21, 16, 20, 0], // 21 = odsazeni shora, 16 = odsazeni pro scrollbar
        spacingTop: control.Container.Ncl.FrgtData.NavigatorOnTop ? 70 : undefined,
        style: {
          fontFamily: "inherit",
        },

        animation: false,
        zooming: { type: control.Container.byOperations() ? "x" : "xy" },
        events: {
          //click: this.props.handleGanttContextMenu,
          //click: (e) => {
          //  this.props.handleGanttContextMenu(e);
          //},
          load: handleLoad,
        },
        height: control.Container.byResources() ? getGanttHeight() : undefined,
      },
      xAxis: [
        {
          //dolni
          min: control.XAxisOptions.minRangeDate == 0 ? null : control.XAxisOptions.minRangeDate * 1000,
          max: control.XAxisOptions.maxRangeDate == 0 ? null : control.XAxisOptions.maxRangeDate * 1000,
          events: {
            afterSetExtremes: (e) => {
              if (!control.Container.Ncl.FrgtData.ZoomReloadData) return;

              if (e.trigger === "zoom" || e.trigger === "navigator" || e.trigger === "rangeSelectorButton") {
                userRangeSelect.current.x1 = e.userMin?.toString();
                userRangeSelect.current.x2 = e.userMax?.toString();

                // if ((e as any)?.rangeSelectorButton?.type === rangeSelectorButtonType) {
                //   userRangeSelect.current.x1 = undefined;
                //   userRangeSelect.current.x2 = undefined;
                // }

                clearTimer(userRangeSelectTimer.current);

                userRangeSelectTimer.current = window.setTimeout(() => {
                  control.userRangeSelect(userRangeSelect.current);
                  userRangeSelect.current = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };
                }, 300);
              }
            },
          },
          visible: !control.Container.hideXAxis(),
          opposite: !control.Container.headerAtBottom(),
          plotBands: control.XAxisOptions.plotBands,
          currentDateIndicator: control.Container.hideDateIndicator()
            ? false
            : {
                width: 2,
                dashStyle: "Solid",
                color: "red",
                label: {
                  format: "%Y-%m-%d",
                },
              },
          //tickInterval: control.Container.timeAxisPrecision(),
          //tickColor: this.props.colors.ContentFrame1,
          //minorTickInterval: control.Container.timeAxisPrecision(), //undefined, //"auto",
          minorGridLineColor: "var(--ColorMap-ContentFrame1)",
          type: "datetime",
          dateTimeLabelFormats: {
            //day: {
            //  list: [`${this.props.showDateTime ? "%d.%m." : ""} %H:%M`, "%a, %e. %b", "%d.%m.", "%d"],
            //},
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. " + __("week") + " %Y", "%W. t."],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          grid: {
            cellHeight: 27 * control.VCXZoom,
            borderColor: "var(--ColorMap-ContentFrame1)",
          },
          labels: {
            allowOverlap: true,
            overflow: "allow",
            padding: 5,
            style: {
              fontSize: "11px",
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
            align: "center",

            formatter: (context) => {
              return formatterLabel(context);
            },
          },
        },
        {
          //horni
          visible: !control.Container.hideXAxis() && control.Container.twoRowXAxis(),
          opposite: !control.Container.headerAtBottom(),
          plotBands: control.XAxisOptions.plotBands,
          type: "datetime",
          grid: {
            cellHeight: 27 * control.VCXZoom,
            borderColor: "var(--ColorMap-ContentFrame1)",
          },
          dateTimeLabelFormats: {
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. " + __("week") + " %Y"],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          labels: {
            allowOverlap: true,
            overflow: "allow",
            style: {
              fontSize: "11px",
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
            align: "center",
            formatter: (context) => {
              return formatterLabel(context);
            },
          },
        },
      ],
      navigator: {
        top: control.Container.Ncl.FrgtData.NavigatorOnTop ? 1 : undefined,
        enabled: !control.Container.hideNavigator(),
        adaptToUpdatedData: false,
        series: {
          visible: false,
          //data: [],
          type: "gantt",
          pointPadding: 0.25,
          pointWidth: null,
        },
        yAxis: {
          reversed: true,
          categories: [],
        },
        xAxis: {
          min: control.XAxisOptions.minRangeDate == 0 ? null : control.XAxisOptions.minRangeDate * 1000,
          max: control.XAxisOptions.maxRangeDate == 0 ? null : control.XAxisOptions.maxRangeDate * 1000,
          gridLineColor: "var(--ColorMap-ContentFrame1)",
          labels: {
            style: {
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
          },
        },
        outlineColor: "var(--ColorMap-ContentFrame1)",
      } as any, // property 'top' neni v dokumentaci, ale na foru ji uvadeji
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          boostThreshold: 1, // Boost when there are more than 1 point in the series.
          cropThreshold: 1,
          animation: false,
          turboThreshold: 0,
          allowPointSelect: true,
          enableMouseTracking: true,
          point: {
            events: {
              mouseOver: function (e) {
                clearTimer(pointHoverTimer.current);

                pointHoverTimer.current = window.setTimeout(() => {
                  const target: any = e.target;
                  const id = target?.series?.userOptions?.type == "gantt" ? target.id : target.name;

                  control.pointHover(id, target?.series?.userOptions?.type);
                }, 200);
              },
              mouseOut: function () {
                clearTimer(pointHoverTimer.current);
              },
            },
          },
        },
        xrange: {
          //tooltip: {
          //  enabled: false,
          //},
          //colorByPoint: true,
          //color: control.CapacitiesColor,
          //colors: [control.CapacitiesColor ? control.CapacitiesColor : "lightgray"],
          allowPointSelect: false, //control.Container.pointSelectAllowed(),
          // enableMouseTracking: false, //control.Container.pointSelectAllowed(),
          showInNavigator: false,
          skipKeyboardNavigation: true,
          zIndex: 5,
          pointPadding: 0,
          groupPadding: 0.1,
          turboThreshold: 0,
          animation: false,
          borderRadius: 0,
          pointWidth: undefined,
          //dragDrop: {
          //  draggableX: false,
          //  draggableY: false,
          //},
          states: {
            hover: {
              animation: false,
              enabled: false,
            },
            inactive: {
              enabled: false,
              animation: false,
            },
            normal: {
              animation: false,
            },
            select: {
              animation: false,
              enabled: true,
            },
          },
          point: {
            events: {
              click: handleCapacityClick,
            },
          },
        },
        gantt: {
          //tooltip: { enabled: !control.HideHint },
          //grouping: true, nechci
          turboThreshold: 0,
          animation: false,
          zIndex: 10,
          borderRadius: 0,
          borderColor: "var(--ColorMap-DataBrowseColorFrg)", //tohle dát asi na parametr
          borderWidth: 1,
          pointPadding: control.Container.byResources() ? 0.1 : 0.15,
          groupPadding: control.Container.byResources() ? 0.2 : 0,
          pointWidth: undefined,
          minPointLength: 1, // Minimum point width. Jinak by se ztráceli při velkém oddálení.
          dataLabels: {
            format: "{point.description}",
          },
          allowPointSelect: control.Container.pointSelectAllowed(),
          //enableMouseTracking: control.Container.pointSelectAllowed(),
          dragDrop: {
            dragMinY: control.Container.byResources() ? 0 : 0,
            dragMaxY: control.Container.byResources() ? control.YAxisOptions.categories.length - 1 : 0,
            draggableStart: control.Container.draggableStartEnd() && control.InEditMode,
            draggableEnd: control.Container.draggableStartEnd() && control.InEditMode,
            dragPrecisionX: control.Container.timeAxisPrecision(), //1000 * 60 * 60 * 24,
            draggableX: control.InEditMode && control.DraggableX,
            draggableY: control.InEditMode && control.DraggableY,
            groupBy: "groupById",
          },
          states: {
            hover: {
              animation: !control.Container.byResources(),
            },
            inactive: {
              enabled: !control.Container.byResources(),
            },
            normal: {
              animation: !control.Container.byResources(),
            },
            select: {
              animation: false,
              enabled: true,
              borderColor: "red",
              borderWidth: 2,
              color: undefined, // pri selectu se obarvi jen ramecek, ne vypln usecky
            },
          },
          point: {
            events: {
              //select: this.props.handleSelect,
              //unselect: this.props.handleUnselect,
              click: handleClick,
              drop: handleDrop,
              drag: handleDrag,
            },
          },
        },
      },
      tooltip: {
        animation: false,
        hideDelay: 0,
        enabled: !control.HideHint,
        shape: "square",
        formatter: function () {
          if (this.series.type === "xrange" && !data.ShowCapacityTooltip) {
            return false;
          }

          return "...";
        },
      },
      scrollbar: {
        enabled: control.Container.hideRangeSelectorDates(),
      },
      // rangeSelector: {
      //   inputEnabled: !control.Container.hideRangeSelectorDates(),
      //   //inputBoxHeight: 0,
      //   //inputBoxWidth: 0,
      //   enabled: !control.Container.hideNavigator(),
      //   verticalAlign: "bottom",
      //   buttonPosition: { align: "right" },
      //   dropdown: "never",
      //   buttons: [
      //     {
      //       type: rangeSelectorButtonType,
      //       text: "reset",
      //     },
      //   ],
      // },
      exporting: {
        enabled: false,
      },
      series: control.SeriesDataItems,
      yAxis: {
        ...control.YAxisOptions,
        visible: !control.Container.hideYAxis(),
        gridLineColor: "var(--ColorMap-ContentFrame1)",
        grid: {
          ...control.YAxisOptions.grid,
          borderColor: "var(--ColorMap-ContentFrame1)",
        },
        scrollbar: {
          enabled: control.Container.byResources(),
          liveRedraw: true,
          showFull: false,
        },
        events: {
          afterSetExtremes: function (e) {
            if (!ganttRef.current) return;

            if (e.trigger === "zoom" && zoom.current !== 1) {
              disableWheel.current = true;
            } else {
              disableWheel.current = false;
            }

            if (e.trigger === "zoom" || e.trigger === "navigator") {
              userRangeSelect.current.y1 = e.userMin?.toString();
              userRangeSelect.current.y2 = e.userMax?.toString();
            }
          },
        },
      },
    };

    if (control.Container.byResources() && ganttRef.current?.chart?.plotHeight) (ganttOptions.yAxis as Highcharts.YAxisOptions).max = getMaxRowCount();

    return ganttOptions;
  }

  useEffect(() => {
    if (prevGanttId.current != null && prevGanttId.current !== data.GanttUniqueIdentifier) {
      initNewGantt.current = true;
    }

    prevGanttId.current = data.GanttUniqueIdentifier;
    zoom.current = control.ClientZoom;

    if (data.Operations.size === 0 && data.Resources.size === 0) {
      setChartOptions(null);
      setVersion(data.GanttUniqueIdentifier);

      return;
    }
    setLoading(true); // Přímé nastavení bez react state update

    initGanttData(data, control, colors, zoom.current);

    const options = initGanttOptions();

    if (initNewGantt.current) {
      // pri prechodu na jiny graf, znovu nainicializovat komponentu, aby se spravne vykreslila vyska osy Y
      setChartOptions(options);
      setVersion(data.GanttUniqueIdentifier);
    } else {
      // pokud prijde update na stejny graf, neupdatovat osu Y, protoze se osa spatne prekresli
      // delete options.yAxis;
      setChartOptions(options);
    }
    initNewGantt.current = false;
  }, [
    data.GanttUniqueIdentifier,
    data.ShowCapacityTooltip,
    data.Operations,
    data.Resources,
    data.Capacities,
    data.DraggableX,
    data.DraggableY,
    data.DayBackgrounds,
    data.HideHint,
    data.MinRangeDate,
    data.MaxRangeDate,
    control.InEditMode,
  ]);

  useEffect(setXDataExtremes, [data.RangeXVersion]);

  useEffect(setYDataExtremes, [data.RangeYVersion]);

  useEffect(() => {
    // schovani car na poslednim (prazndem) radku
    if (control.Container.byResources()) {
      hideLine(".highcharts-grid-line");
      hideLine(".highcharts-tick");
    }
  });

  function setXDataExtremes() {
    if (ganttRef.current && ganttRef.current.chart)
      if (control.Container?.Ncl?.FrgtData?.ZoomReloadData) {
        const axis = ganttRef.current.chart.xAxis[0];
        const min = data.RangeXMin;
        const max = data.RangeXMax;
        axis?.setExtremes(
          min >= 0 && min != control.XAxisOptions.minRangeDate * 1000 ? min : null,
          max >= 0 && min != control.XAxisOptions.maxRangeDate * 1000 ? max : null,
          true
        );
      }
  }

  function setYDataExtremes() {
    if (ganttRef.current && ganttRef.current.chart)
      if (control.Container?.Ncl?.FrgtData?.ZoomReloadData) {
        const axis = ganttRef.current.chart.yAxis[0];
        if ((axis.min != data.RangeYMin || axis.max != data.RangeYMax) && (data.RangeYMin >= 0 || data.RangeYMax >= 0)) {
          ganttRef.current.chart.showResetZoom();
        }
        axis?.setExtremes(data.RangeYMin >= 0 ? data.RangeYMin : null, data.RangeYMax >= 0 ? data.RangeYMax : null, true);
      }
  }

  function hideLine(selector: string) {
    const lines = document.querySelectorAll(selector);
    const line = lines[lines.length - 2];

    if (line instanceof SVGPathElement) {
      line.style.display = "none";
    }
  }

  function clearTimer(timer: number) {
    if (timer) {
      window.clearTimeout(timer);
    }
  }

  const getBgColor = () => {
    return control.OwnBackgroundColor
      ? control.OwnBackgroundColor
      : !control.InEditMode
      ? "var(--ColorMap-DataBrowseColorBck)"
      : "var(--ColorMap-DataChangeColorBck)";
  };

  function formatterLabel(context: Highcharts.AxisLabelsFormatterContextObject) {
    if (context.dateTimeLabelFormat) {
      const pos: number = context.pos; // as number;
      const value: string = Highcharts.dateFormat(context.dateTimeLabelFormat, pos);
      const dayNum: number = new Date(pos).getDay();
      if (dayNum == 6 || dayNum == 0) {
        return `<span style="color: red">${value}</span>`;
      } else {
        return value;
      }
    } else return "";
  }

  const handleClick = (e: Highcharts.PointClickEventObject) => {
    handleClickInternal(e, 0, (e.point.series.options as any).allowPointSelect /* !control.Container.pointSelectAllowed()*/); //intervaly
    return false; //vypinam implicitni obsluhu onClick (oznaceni usecky)
  };

  function handleDrag(e: Highcharts.PointDragEventObject) {
    if (control.Container.Ncl.FrgtData.UseRestrictKeys) {
      const dragDropOptions = e.target.options.dragDrop;

      switch (restrictKey.current) {
        case "Control":
          dragDropOptions.draggableX = true;
          dragDropOptions.draggableY = false;
          break;
        case "Shift":
          dragDropOptions.draggableX = false;
          dragDropOptions.draggableY = true;
          break;
        default:
          return false;
      }
    }
  }

  const handleCapacityClick = (e: Highcharts.PointClickEventObject) => {
    handleClickInternal(e, 1, false); //kapacity
    return false;
  };

  const handleClickInternal = (e: Highcharts.PointClickEventObject, t: number, pointSelectAllowed: boolean) => {
    if (e.point?.options?.id === undefined) return;
    const id: string = t === 0 ? e.point.options.id.toString() : e.point.options.name.toString();

    clickCount.current += 1;

    if (clickCount.current === 2) {
      control.defaultAccept([id, t.toString()]);
    }

    if (!clickTimer.current)
      clickTimer.current = window.setTimeout(() => {
        if (clickCount.current === 1) {
          if (pointSelectAllowed) {
            if (e.shiftKey) {
              control.defaultAccept([id, t.toString()]);
            } else {
              if (!e.point.selected) {
                control.selectPoint([id, t.toString(), e.ctrlKey ? "1" : "0"]);
              } else {
                control.unselectPoint([id, t.toString(), e.ctrlKey ? "1" : "0"]);
              }
              //ve Vyrobnim planovaci je zakernost ze se pomoci K2 selectuji/unselectuji zaroven s touto i jine usecky
            }
          } else {
            control.ganttGetRecord([id, t.toString()]);
          }
        }
        clickCount.current = 0;
        clickTimer.current = 0;
      }, 300);
  };

  const handleGanttContextMenu = (e: Highcharts.PointerEventObject) => {
    control.ganttContextMenu();
  };

  function handleSelect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    control.selectPoint([point.options.id, e.accumulate]);
  }

  function handleUnselect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    control.unselectPoint([point.options.id, e.accumulate]);
  }

  const handleRangeSelect = (e: Highcharts.AxisSetExtremesEventObject) => {
    control.rangeSelect([e.min.toString(), e.max.toString()]);
  };

  const handleDrop = (e: Highcharts.PointDropEventObject) => {
    if ((control.Container.Ncl.FrgtData.UseRestrictKeys && restrictKey.current) || !control.Container.Ncl.FrgtData.UseRestrictKeys) {
      const updatedData = updateDataAfterDrop(e);
      control.change(["UpdatedData", updatedData.length.toString(), updatedData.toString(), "TargetID", e.target.options.id.toString()]);

      return true;
    }

    return false;
  };

  const handleWheel = (e: React.WheelEvent) => {
    if (!e.shiftKey || disableWheel.current) return;

    const newZoom = zoom.current - (e.deltaY * zoom.current) / 1000;

    if (newZoom < 1) return;

    (ganttRef.current.chart.yAxis[0] as any).staticScale = 0;
    zoom.current = newZoom;

    initGanttData(data, control, colors, zoom.current);
    setChartOptions(initGanttOptions());
    setVersion(data.GanttUniqueIdentifier + Date.now()); // gantt neumi dynamicky menit vysku radku (staticScale), takze kompletne znovu inicializuji komponentu
    control.setClientZoom([zoom.current.toString()]);
  };

  function handleLoad(e: Event) {
    setTimeout(() => {
      setXDataExtremes();
      setYDataExtremes();
    });
    setTimeout(() => {
      setXDataExtremes();
      setYDataExtremes();
    }, 50);
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey) {
      restrictKey.current = e.key;
    }

    if (e.shiftKey) {
      restrictKey.current = e.key;
    }
  }

  function handleKeyUp() {
    restrictKey.current = "";
  }

  function onResize(width?: number, height?: number) {
    if (!initNewGantt.current && prevSize.current.height != height) {
      const options = initGanttOptions();
      const needMaxRowUpdate = (chartOptions?.yAxis as Highcharts.YAxisOptions)?.max !== (options?.yAxis as Highcharts.YAxisOptions)?.max;
      if (!needMaxRowUpdate) delete options.yAxis;
      setChartOptions(options);
      if (needMaxRowUpdate) {
        setVersion(data.GanttUniqueIdentifier + Date.now());
      }
    }

    if (control.Container.byResources()) ganttRef.current?.chart?.setSize(width, height);
    ganttRef.current?.chart?.reflow();
    sendResolution();

    prevSize.current = { width: width, height: height };
  }

  if (!colors || chartOptions == null) {
    content = NoData(colors);
  } else {
    content = <HighchartsReact key={version} ref={ganttRef} highcharts={Highcharts} options={chartOptions} constructorType={"ganttChart"} />;
    setLoading(false);
  }

  return (
    <ReactResizeDetector>
      <div
        ref={element}
        style={StyleHelper(control, {
          ...props.style,
          flex: "1 1 auto",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        })}
        className={css.gantt}
        {...getAttributes(data)}
      >
        <div
          ref={ganttWrapper}
          className="gantt-wrapper"
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            backgroundColor: getBgColor(),
          }}
          onWheel={handleWheel}
        >
          {content}
          <div className="loading_wrap">
            <K2BusyIndicator visibility={true} type={BusyIndicatorType.bitInfinityRound} backgroundShadow={false} align={BusyIndicatorAlign.biaCenter} />
          </div>
        </div>
        {control.Container.showFooter() && chartOptions && (
          <K2GanttFooter controlUID={control.Container?.Footer?.MetaData?.ControlUID} vrUID={control.getRealizerUID()} />
        )}
      </div>
    </ReactResizeDetector>
  );
};

export default K2InnerGantt;
