import { ElementHtmlAttributes } from "../../common/common";
import { CondFormattingStyle, TCondFormattingOption, UpdateInput } from "../../common/communication.base";
import { NclInput } from "../../common/components.ncl";
import { VCXColorMap, VisualContext } from "../../common/visualContext";
import { WithContextPlacementProps } from "../k2hoc";

export interface InputHtmlAttributes extends ElementHtmlAttributes {
  type: string;
  id: string;

  maxLength?: number;
  autoComplete?: string;
  placeholder?: string;
  pattern?: string;
  title?: string;

  min?: number;
  max?: number;
  step?: number;
}

export interface InputProps extends WithContextPlacementProps {
  blockSoftKeyboard?: boolean;
}

export interface InnerInputProps extends WithContextPlacementProps, InputProps {
  revealPassword: boolean;
  focus: boolean;
  value: string;
  position: number;
  control: NclInput;
  data: UpdateInput;
  invalidDate?: boolean;
  lastSelectedPosition?: React.MutableRefObject<{ start: number; end: number }>;
  onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  change: (value: string) => void;
  initRef: (element: HTMLInputElement | HTMLTextAreaElement) => void;
  setValue: (value: string) => void;
}

export function createHint(hint: string, value: string): string {
  if (hint && value) return `${hint} : ${value}`;
  if (hint) return hint;
  if (value) return value;

  return undefined;
}

export class ColumnBaseHelper {
  static getForegroundColor(formatting: CondFormattingStyle, isSelected: boolean, fixed: boolean, vcx: VisualContext): string {
    if (this.UseForegroundColor(formatting)) {
      return vcx.getColor(formatting.ForegroundColor);
    }
    return null;
  }

  static getBackgroundColor(formatting: CondFormattingStyle, isSelectedColumn: boolean, fixed: boolean, vcx: VisualContext): string {
    let result = "inherit";
    if (isSelectedColumn) {
      result = vcx.getColor(vcx.Data.ColorMap.GridRulerColorBck);
    }

    if (this.UseBackgroundColor(formatting)) {
      if (isSelectedColumn) {
        result = VCXColorMap.rgbToHex(
          VCXColorMap.combineAlpha(vcx.getRGBColor(formatting.BackColor), vcx.getRGBColor(vcx.Data.ColorMap.GridRulerColorBck), 180)
        );
      } else {
        result = vcx.getColor(formatting.BackColor);
      }
    } else {
      if (fixed) {
        if (isSelectedColumn) {
          result = VCXColorMap.rgbToHex(
            VCXColorMap.combineAlpha(vcx.getRGBColor(vcx.Data.ColorMap.DataChangeROColorBck), vcx.getRGBColor(vcx.Data.ColorMap.GridRulerColorBck), 180)
          );
        } else {
          result = vcx.getColor(vcx.Data.ColorMap.DataChangeROColorBck);
        }
      }
    }

    return result;
  }
  static IsBold(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleBold) === TCondFormattingOption.cfoFontStyleBold;
  }

  static IsItalic(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleItalic) === TCondFormattingOption.cfoFontStyleItalic;
  }

  static IsStrike(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleStrike) === TCondFormattingOption.cfoFontStyleStrike;
  }

  static IsUnderline(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleUnderline) === TCondFormattingOption.cfoFontStyleUnderline;
  }

  static UseBackgroundColor(formatting: CondFormattingStyle): boolean {
    return formatting && formatting.BackColor > 0 && (formatting.Options & TCondFormattingOption.cfoBackColor) === TCondFormattingOption.cfoBackColor;
  }

  static UseForegroundColor(formatting: CondFormattingStyle): boolean {
    return (
      formatting &&
      formatting.ForegroundColor > 0 &&
      (formatting.Options & TCondFormattingOption.cfoForegroundColor) === TCondFormattingOption.cfoForegroundColor
    );
  }
}
