import React from "react";
import { CSColumn, TBehaviorTypeByDevice } from "../../common/communication.base";
import K2Cell from "./K2Cell";
import { getFixedColumnStyles, HeaderColumnProps } from "./utils";
import css from "./DataGrid.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";
import { Context } from "../../appcontext";

interface ColumnProps extends HeaderColumnProps {
  value: CSColumn;
  fixed: boolean;
  canEdit: boolean;
  isSelectedRow: boolean;
  rowNdx: number;
  execute: (commandNumber: number) => void;
}

interface ColumnState {
  isSelectedColumn: boolean;
}

export class K2Column extends React.PureComponent<ColumnProps, ColumnState> {
  static displayName = `K2Column`;
  private element: HTMLElement;

  constructor(props: ColumnProps) {
    super(props);
    this.state = { isSelectedColumn: false };
  }

  updateSelected(selectedCell: boolean) {
    this.setState({ isSelectedColumn: selectedCell });
    if (selectedCell && this.element && Context.DeviceInfo.ExplicitMaxRowCount === -1) {
      this.element.scrollIntoView();
    }
  }

  setAsActive(isActive: boolean) {}

  getOverRect(): DOMRect {
    if (this.element) {
      this.element.scrollIntoView();
      return this.element.getBoundingClientRect();
    }

    return null;
  }

  componentDidMount(): void {
    resolveContextMenu(this.element, this.handleContextMenu);
  }

  getElement(): HTMLElement {
    return this.element;
  }

  render() {
    const triangle: JSX.Element = this.getStateTriangle();

    return (
      <td
        style={{
          ...getFixedColumnStyles(this.props.columnProportion.Width, this.props.columnOffset),
          height: "inherit",
        }}
        className={css.dg_td + `${this.props.fixed ? " dg_fixed_col" : ""}`}
        onClick={this.handleClick}
        ref={(ref) => {
          this.element = ref;
        }}
      >
        {triangle}
        <K2Cell
          vcx={this.props.vcx}
          text={this.props.value.Text}
          multiTexts={this.props.value.MultiTexts}
          measure={this.props.columnProportion.MFMeasure}
          glyphId={this.props.value.GlyphId}
          isSelectedText={this.props.value.IsSelectedText}
          isSelectedCell={this.state.isSelectedColumn}
          isSelectedRow={this.props.isSelectedRow}
          isCancelled={this.props.value.IsCancelled === true}
          isLinkCell={this.props.value.IsLinkAction}
          isFixed={this.props.fixed}
          alignment={this.props.columnProportion.Alignment}
          condFormatting={this.props.value.CondFormatting}
          isDenied={this.props.value.UpdateValueState}
          rowHeightMultiplier={this.props.rowHeightMultiplier}
          execute={this.props.execute}
        />
      </td>
    );
  }

  private getStateTriangle(): JSX.Element {
    if (this.props.value) {
      if (this.props.value.IsModified || this.props.value.HasError) {
        return <div className={`${css.dg_triangle}${this.props.value.HasError ? `${css.dg_triangle_error}` : ""}`} />;
      }
    }

    return null;
  }

  private timeout: any;
  private clickCount = 0;

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.onColumnClick) {
      const shiftKey = e.shiftKey;
      const ctrlKey = e.ctrlKey;

      if (!(Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS)) {
        //Normální prohlížeče ne iOS ...
        switch (e.detail) {
          case 0:
          case 1:
            this.props.onColumnClick.call(this, this.props.rowNdx, this.props.columnNdx, 1, shiftKey, ctrlKey);
            break;
          default:
            this.props.onColumnClick.call(this, this.props.rowNdx, this.props.columnNdx, 2, shiftKey, ctrlKey);
        }
      } else {
        ///IOS
        if (this.clickCount <= 0)
          this.timeout = setTimeout(() => {
            if (this.clickCount >= 2) {
              this.props.onColumnClick.call(this, this.props.rowNdx, this.props.columnNdx, 2, e.shiftKey, e.ctrlKey);
            } else {
              this.props.onColumnClick.call(this, this.props.rowNdx, this.props.columnNdx, 1, e.shiftKey, e.ctrlKey);
            }
            this.clickCount = 0;
          }, 300);

        this.clickCount++;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  private handleContextMenu = (e: MouseEvent) => {
    if (this.props.onColumnContextMenu) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onColumnContextMenu.call(this, this.props.rowNdx, this.props.columnNdx, null);
    }
  };
}
