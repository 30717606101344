import {
  VisualContextMetadata,
  VCXFontNoColorMetaData,
  FontStyle,
  VCXMetaData,
  VCXDataInputControlMetaData,
  VCXLabelBaseMetaData,
  K2JSONObject,
  VCXColorMapMetaData,
  VCXExpanderControlMetaData,
  VCXTabControlMetaData,
  VCXViewControlMetaData,
  VCXGridControlMetaData,
  DataActionDecorate,
  TColorShiftLevel,
  MFMeasure,
  MFSectionMeasureItem,
  MFBlocksMeasureItem,
  MFBlockMeasureItem,
  VCXDashboardControlMetaData,
  FormatterColor,
  FormatterColorMode,
  VCXListViewControlMetaData,
  VCXTreeViewControlMetaData,
  VCXTitleControlMetaData,
  VCXSplitterControlMetaData,
} from "./communication.base";
import { version } from "punycode";
import { computeTextDimension, Log } from "./common";
import { Context } from "../appcontext";

export const UFInteriorMargin = 1; // Pevny margin obsahu (napr headeru v expanderu)

export const defaultVCX = {
  SimpleChartControl: {
    ChartCaptionFont: { FontStyle: 0, FontSize: 14, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCSimpleChartControl",
  },
  ColorMap: {
    Alpha: 0,
    ContentDecorateColorFrg: 4882945,
    DataChangeROColorFrg: 0,
    ContentChangeDecorateColorFrg: 4882945,
    DataChangeROColorBck: 16250871,
    InactiveAlphaValue: 40,
    ContentFrameMinForChange: 14614189,
    ContentNormalColorFrg: 0,
    DataBrowseColorFrg: 0,
    DataBrowseColorBck: 16579836,
    ContentFrameMin: 15132390,
    InactiveAlphaColor: 0,
    DataChangeColorFrg: 0,
    DataChangeColorBck: 14811135,
    WarningColorFrg: 16777215,
    WarningColorBck: 42495,
    HighlightColorSet: {
      Color8: 15522557,
      Color7: 12441829,
      Color6: 11465471,
      Color5: 10934782,
      Color4: 14994398,
      Color3: 12970956,
      Color2: 14929331,
      Color1: 11449595,
      __type: "TUFVCColorSet",
    },
    AlphaColor: 0,
    ContentFrame1ForChange: 13499782,
    BaseColorFrg1: 16777215,
    BaseColorBck1: 4882945,
    ContentColorBck1: 16250871,
    ContentFrame2ForChange: 12516449,
    LumaShift: 0,
    ContentFrame3ForChange: 9432068,
    ContentChangeColorBck: 15335115,
    ContentFrame3: 8487297,
    ContentFrame2: 12369084,
    ContentFrame1: 13750737,
    ErrorColorFrg: 16777215,
    ErrorColorBck: 4474111,
    Gray: 0,
    GridRulerColorBck: 15335115,
    AccentBaseColorFrg: 16777215,
    AccentBaseColorBck: 7983106,
    HighLightColorFrg: 16777215,
    HighLightColorBck: 52377,
    __type: "TUFVCColorMap",
  },
  DecorateMode: 0,
  ExpanderControl: {
    HeaderFont: { FontStyle: 1, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCExpanderControl",
    LiteSeparationWidth: 0,
  },
  SplitterControl: { Size: 7, __type: "TUFVCSplitterControl" },
  Caption: "BOHEMIANS PRAHA 1905",
  Description: "BOHEMIANS PRAHA 1905",
  BookTabControl: {
    CurrentAccessorFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    AccessorFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCTabControl",
  },
  GridControl: {
    HeadingFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCGridControl",
  },
  Placement: { MaximizeAll: 0, __type: "TUFVCPlacement" },
  TreeViewControl: {
    HeadingFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCTreeViewControl",
  },
  DashboardControl: {
    TileFont: { FontStyle: 0, FontSize: 14, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    TileSmallFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTableFixedFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    GraphFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    SimpleTableFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    FilterInnerFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTablePropertiesFont: { FontStyle: 0, FontSize: 6, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTableValueFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCDashboardControl",
  },
  ViewControl: { OutLineWidth: 1, FrameWidth: 1, Margin: 3, __type: "TUFVCViewControl" },
  UI: "{94B5DFDC-2674-40AD-91E5-8D392A28CDCB}",
  MarginY: 3,
  MarginX: 3,
  DataInputControl: {
    FrameWidth: 1,
    InputLabel: { Font: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCInputLabel" },
    __type: "TUFVCInputControl",
    InputEdit: { InteriorBorder: 0, Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCInputEditBase" },
  },
  ListViewControl: {
    SmallIconSize: 16,
    LargeIconSize: 32,
    Font: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCListViewControl",
  },
  Zoom: 1,
  LabelControl: { Font: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCLabelControl" },
  TitleControl: { Font: { FontStyle: 5, FontSize: 15, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCTitleControl" },
  __type: "TUFVisualContext",
} as any;

function generateHash(str: string) {
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash = (hash << 7) - hash + charCode;
    hash = hash & hash;
  }
  return hash;
}
export abstract class VisualContext {
  protected data: VisualContextMetadata;
  private zoomFactorDiv100: number;
  private zoomFactor: number;
  private ic: VCXInput;
  private lc: VCXLabel;
  private cm: VCXColorMap;
  private ec: VCXExpander;
  private btc: VCXBookTab;
  private vc: VCXView;
  private gc: VCXGrid;
  private static defVCX: VisualContext;
  protected version: number;
  private dc: VCXDashboard;
  private lwc: VCXListView;
  private twc: VCXTreeView;
  private titlecontrol: VCXTitle;
  private sc: VCXSplitterControl;

  protected constructor(data: VisualContextMetadata, zoomFactor: number) {
    this.data = data;
    this.zoomFactor = zoomFactor;
    this.zoomFactorDiv100 = zoomFactor / 100;
    this.version = generateHash(JSON.stringify(data)) * this.zoomFactorDiv100;
  }

  private static createInternal(data: VisualContextMetadata, zoomFactor: number): VisualContext {
    return new VCX(data, zoomFactor);
  }

  public static create(data: VisualContextMetadata, zoomFactor: number): VisualContext {
    if (!VisualContext.defVCX) {
      VisualContext.setDefault(data, zoomFactor);
    }
    return new VCX(data, zoomFactor);
  }

  public static get Default(): VisualContext {
    if (!VisualContext.defVCX) {
      VisualContext.defVCX = VisualContext.createInternal(defaultVCX, 100);
    }
    return VisualContext.defVCX;
  }

  private static setDefault(value: VisualContextMetadata, zoomFactor: number) {
    if (VisualContext.defVCX) {
      throw new Error("Mishmash default VCX.");
    }

    VisualContext.defVCX = VisualContext.createInternal(value, zoomFactor);
  }

  public getVersion(): number {
    return this.version;
  }

  public get ZoomFactor(): number {
    return this.zoomFactor;
  }

  public get Zoom(): number {
    return this.zoomFactor * this.data.Zoom;
  }

  public get Data(): VisualContextMetadata {
    return this.data;
  }

  public get InputControl(): VCXInput {
    if (!this.ic) {
      this.ic = new VCXInput(this.data.DataInputControl, this.getCorrectParent());
    }

    return this.ic;
  }

  public get LabelControl(): VCXLabel {
    if (!this.lc) {
      this.lc = new VCXLabel(this.data.LabelControl, this.getCorrectParent());
    }

    return this.lc;
  }

  public get ExpanderControl(): VCXExpander {
    if (!this.ec) {
      this.ec = new VCXExpander(this.data.ExpanderControl, this.getCorrectParent());
    }

    return this.ec;
  }

  public get BookTabControl(): VCXBookTab {
    if (!this.btc) {
      this.btc = new VCXBookTab(this.data.BookTabControl, this.getCorrectParent());
    }

    return this.btc;
  }

  public get ViewControl(): VCXView {
    if (!this.vc) {
      this.vc = new VCXView(this.data.ViewControl, this.getCorrectParent());
    }

    return this.vc;
  }

  public get GridControl(): VCXGrid {
    if (!this.gc) {
      this.gc = new VCXGrid(this.data.GridControl, this.getCorrectParent());
    }

    return this.gc;
  }

  public get ColorMap(): VCXColorMap {
    if (!this.cm) {
      this.cm = new VCXColorMap(this.data.ColorMap, this.getCorrectParent());
    }

    return this.cm;
  }

  public get MinRowHeight(): number {
    return this.LabelControl.getHeight(1);
  }

  public get DashboardControl(): VCXDashboard {
    if (!this.dc) {
      this.dc = new VCXDashboard(this.data.DashboardControl, this.getCorrectParent());
    }

    return this.dc;
  }

  public get ListViewControl() {
    if (!this.lwc) {
      this.lwc = new VCXListView(this.data.ListViewControl, this.getCorrectParent());
    }

    return this.lwc;
  }

  public get TreeViewControl() {
    if (!this.twc) {
      this.twc = new VCXTreeView(this.data.TreeViewControl, this.getCorrectParent());
    }

    return this.twc;
  }

  public get TitleControl() {
    if (!this.titlecontrol) {
      this.titlecontrol = new VCXTitle(this.data.TitleControl, this.getCorrectParent());
    }

    return this.titlecontrol;
  }

  public get SplitterControl() {
    if (!this.sc) {
      this.sc = new VCXSplitterControl(this.data.SplitterControl, this.getCorrectParent());
    }

    return this.sc;
  }

  public sizeMap(size: number): number {
    if (this.data == null) {
      return size;
    }
    return Math.fround(size * (this.data.Zoom * Context.DeviceInfo.ZoomFactor) * this.zoomFactorDiv100);
  }

  public cssSizeMap(size: number, unit = "px"): string {
    if (this.data == null) {
      return size.toString() + unit;
    }
    return this.sizeMap(size) + unit;
  }

  public interioirMargin(value: number): number {
    return value * UFInteriorMargin;
  }

  public getColor(key: number): string {
    return this.ColorMap.getColor(key);
  }

  public getRGBColor(key: number): RGB {
    return this.ColorMap.getColorRGB(key);
  }

  public getFormatterColor(fromColor: number, color: FormatterColor): string {
    switch (color.ColorMode) {
      case FormatterColorMode.fcmColor:
        return this.getColor(color.Color);
      case FormatterColorMode.fcmDarken:
        return VCXColorMap.rgbToHex(VCXColorMap.shiftColorLuma(VCXColorMap.intToRGB(fromColor), color.Color * -1));
      case FormatterColorMode.fcmDiferent:
        return this.getColor(VCXColorMap.getDifferentColor(fromColor, color.Color));
      case FormatterColorMode.fcmLighten:
        return VCXColorMap.rgbToHex(VCXColorMap.shiftColorLuma(VCXColorMap.intToRGB(fromColor), color.Color));
      case FormatterColorMode.fcmVCXColor:
        return this.getColor(color.Color);
    }
    return undefined;
  }

  public invalidateCache() {
    const vcx: VCX = this.getCorrectParent();
    if (vcx) {
      vcx.internalInvalidateCache();
    }
  }

  protected abstract getCorrectParent(): VCX;
  public abstract createVCXForZoomFactor(zoomFactor: number): VisualContext;

  public abstract calcMFMeasure(measure: MFMeasure): boolean;
}

interface FontMeasures {
  TextHeight?: number;
  Widths: Map<string, number>;
}

const unsupportedFonts: Array<string> = [];
const supportedFonts: Array<string> = [];
const safeFont = "sans-serif";

class VCX extends VisualContext {
  private cacheFont: Map<string, FontMeasures>;
  private cache: Map<number, VisualContext>;

  public constructor(data: VisualContextMetadata, zoomFactor: number) {
    super(data, zoomFactor);
    this.cacheFont = new Map<string, FontMeasures>();
    this.cache = new Map<number, VisualContext>();
    this.internalInvalidateCache();
  }

  public internalInvalidateCache() {
    this.cacheFont.clear();
  }

  private calcMesures(font: VCXFontNoColorMetaData) {
    const unsupportedFont = unsupportedFonts.indexOf(font.FontName);
    const supportedFont = supportedFonts.indexOf(font.FontName);

    if (unsupportedFont === -1 && supportedFont === -1) {
      if (!this.fontExists(font.FontName)) {
        unsupportedFonts.push(font.FontName);
        font.FontName = safeFont;
      } else {
        supportedFonts.push(font.FontName);
      }
    } else if (unsupportedFont > -1) {
      font.FontName = safeFont;
    }

    const mc = computeTextDimension("M", font, this.sizeMap(font.FontSize));
    const measure: FontMeasures = {
      TextHeight: mc.height,
      Widths: new Map([
        ["0", computeTextDimension("0", font, this.sizeMap(font.FontSize)).width],
        ["*", computeTextDimension("*", font, this.sizeMap(font.FontSize)).width],
        ["M", mc.width],
      ]),
    };

    this.cacheFont.set(JSON.stringify(font), measure);
  }

  public fontExists(fontName: string) {
    let canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const text = "abcdefghijklmnopqrstuvwxyz0123456789";

    context.font = "72px monospace";
    const baseFontSize = context.measureText(text).width;

    context.font = `72px "${fontName}", monospace`;
    const newFontSize = context.measureText(text).width;
    canvas = null;

    if (newFontSize === baseFontSize) {
      return false;
    } else {
      return true;
    }
  }

  public getMeasures(data: VCXFontNoColorMetaData): FontMeasures {
    let key = JSON.stringify(data);
    if (!this.cacheFont.has(key)) {
      this.calcMesures(data);
    }

    if (!this.cacheFont.has(key)) key = JSON.stringify(data); // FontName can be changed

    return this.cacheFont.get(key);
  }

  public getTextWidth(text: string, font: VCXFontNoColorMetaData): number {
    const measures: FontMeasures = this.getMeasures(font);

    if (measures) {
      if (measures.Widths.has(text)) {
        return measures.Widths.get(text);
      } else {
        const width = computeTextDimension(text, font, this.sizeMap(font.FontSize)).width;
        measures.Widths.set(text, width);
        return width;
      }
    }

    throw Error("Tohle by se nemelo stat :D.");
  }

  protected getCorrectParent(): VCX {
    return this;
  }

  public createVCXForZoomFactor(zoomFactor: number): VisualContext {
    let vcx: VisualContext;
    const zoomF = this.ZoomFactor * (zoomFactor / 100);
    if (!this.cache.has(zoomF)) {
      vcx = new VCX(this.data, zoomF);
      this.cache.set(zoomF, vcx);
    } else {
      vcx = this.cache.get(zoomF);
    }

    return vcx;
  }

  public calcMFMeasure(measure: MFMeasure): boolean {
    if (!measure) return false;

    if (measure.Height) return true;

    let lineHeight = 0;

    if (measure.Sections) {
      measure.Sections.map((section) => {
        if (this.calcMFSection(section)) {
          lineHeight += section.Height;
        } else {
          Log.error(`Cant't calculate multiformat section`, null);
          return false;
        }
      });
    }
    measure.Height = lineHeight + 4 * UFInteriorMargin;
    return true;
  }

  private calcMFSection(section: MFSectionMeasureItem): boolean {
    if (!section) return false;
    if (section.Height) return true;
    let lineHeight = 0;

    if (section.LBlocks) {
      if (this.calcMFBand(section.LBlocks)) {
        if (section.LBlocks.Height > lineHeight) lineHeight = section.LBlocks.Height;
      }
    }

    if (section.Bands) {
      let blh = 0;
      section.Bands.map((band) => {
        if (this.calcMFBand(band)) {
          if (band.Height > 0) blh += band.Height;
        } else {
          Log.error(`Cant't calculate multiformat band`, null);
          return false;
        }
      });
      if (blh > lineHeight) lineHeight = blh;
    }

    if (section.RBlocks) {
      if (this.calcMFBand(section.RBlocks)) {
        if (section.RBlocks.Height > lineHeight) lineHeight = section.RBlocks.Height;
      }
    }

    section.Height = lineHeight;
    return true;
  }

  private calcMFBand(band: MFBlocksMeasureItem): boolean {
    if (!band) return false;
    if (band.Height) return true;
    let lineHeight = 0;
    let lines = 0;

    if (band.Blocks) {
      band.Blocks.map((block) => {
        if (block.Attrs) {
          let vcx: VisualContext = this;
          if (block.Attrs.Font && block.Attrs.Font.Enabled && block.Attrs.Font.Zoom * this.ZoomFactor != this.ZoomFactor) {
            vcx = this.createVCXForZoomFactor(block.Attrs.Font.Zoom * 100);
          }
          const h = vcx.GridControl.GetRowHeight(block.Attrs.LineCount);
          if (h > lineHeight) lineHeight = h;
          if (block.Attrs.LineCount > lines) lines = block.Attrs.LineCount;
        } else {
          Log.error(`Attrs of block not found`, null);
          return false;
        }
      });
    }
    band.Height = lineHeight;
    band.MaxLineCount = lines;
    return true;
  }
}

class VCXControl<T extends VCXMetaData> implements K2JSONObject {
  protected parent: VCX;
  protected data: T;
  constructor(data: T, parent: VCX) {
    this.parent = parent;
    this.data = data;
  }

  public get Data(): T {
    return this.data;
  }

  public get __type(): string {
    return this.__type;
  }
}

export class VCXFontNoColor extends VCXControl<VCXFontNoColorMetaData> {
  constructor(data: VCXFontNoColorMetaData, parent: VCX) {
    super(data, parent);
  }

  public get IsBold(): boolean {
    return (this.data.FontStyle & FontStyle.fsBold) === FontStyle.fsBold;
  }

  public get IsItalic(): boolean {
    return (this.data.FontStyle & FontStyle.fsItalic) === FontStyle.fsItalic;
  }

  public get IsUnderline(): boolean {
    return (this.data.FontStyle & FontStyle.fsUnderline) === FontStyle.fsUnderline;
  }

  public get IsStrikeOut(): boolean {
    return (this.data.FontStyle & FontStyle.fsStrikeOut) === FontStyle.fsStrikeOut;
  }

  public get FontSize(): number {
    return this.parent.sizeMap(this.data.FontSize);
  }

  public get FontName(): string {
    if (unsupportedFonts.indexOf(this.data.FontName) > -1) {
      return safeFont;
    }

    return this.data.FontName;
  }

  public get TextHeight() {
    return this.getMeasures().TextHeight;
  }

  public get _0Width() {
    return this.getMeasures().Widths.get("0");
  }

  public get _MWidth() {
    return this.getMeasures().Widths.get("M");
  }

  public get _AstWidth() {
    return this.getMeasures().Widths.get("*");
  }

  public cloneWith(data: VCXFontNoColorMetaData): VCXFontNoColor {
    return new VCXFontNoColor(Object.assign({}, this.data, data), this.parent);
  }

  public computeTextWidth(value: string): number {
    return this.parent.getTextWidth(value, this.data);
  }

  private getMeasures(): FontMeasures {
    return this.parent.getMeasures(this.data);
  }
}

class VCXExpander extends VCXControl<VCXExpanderControlMetaData> {
  private hFont: VCXFontNoColor;
  private lFont: VCXFontNoColor;

  public get HeaderFont(): VCXFontNoColor {
    if (!this.hFont) {
      this.hFont = new VCXFontNoColor(this.data.HeaderFont, this.parent);
    }

    return this.hFont;
  }

  public GetHFHeight(): number {
    return this.HeaderFont.TextHeight + 2 * this.GetHFMargin() + 2 * UFInteriorMargin;
  }

  public GetHFMargin(): number {
    return Math.floor(this.HeaderFont.TextHeight / 5) + this.parent.InputControl.getInputInteriorBorder();
  }
}

export class VCXGrid extends VCXControl<VCXGridControlMetaData> {
  private hFont: VCXFontNoColor;
  private font: VCXFontNoColor;

  public get HeaderFont(): VCXFontNoColor {
    if (!this.hFont) {
      this.hFont = new VCXFontNoColor(this.data.HeadingFont, this.parent);
    }

    return this.hFont;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }

  public get LineWidth(): number {
    return 1;
  }

  public GetRowHeightWithoutMargin(multiplier = 1): number {
    return this.Font.TextHeight * multiplier;
  }

  public GetHeaderRowHeight(): number {
    return this.HeaderFont.TextHeight + 4 * UFInteriorMargin;
  }

  public GetRowHeight(multiplier: number): number {
    return this.Font.TextHeight * multiplier + 4 * UFInteriorMargin; //4*....původně bylo 2* ale našel jsem že je v řádku gridu ještě 1px transparentní border na každé buňce,
    //který je při selected dotted. Aby neskákala výška řádku.
  }

  GetExteriorCellWidth(width: number): number {
    return width + 10 * UFInteriorMargin;
  }
}

class VCXInput extends VCXControl<VCXDataInputControlMetaData> {
  private lFont: VCXFontNoColor;
  private iFont: VCXFontNoColor;
  public getInputHeight(lines: number, showFrame: boolean, showLabel: boolean): number {
    let result: number = this.getEditHeight(lines);
    if (showFrame) {
      result += this.getInputFrameHeight();
    }

    if (showLabel) {
      result += this.parent.LabelControl.Font.TextHeight;
    }

    return result;
  }

  public get LabelFont(): VCXFontNoColor {
    if (!this.lFont) {
      this.lFont = new VCXFontNoColor(this.data.InputLabel.Font, this.parent);
    }

    return this.lFont;
  }

  public get InputFont(): VCXFontNoColor {
    if (!this.iFont) {
      this.iFont = new VCXFontNoColor(this.data.InputEdit.Font, this.parent);
    }

    return this.iFont;
  }

  private getInputFrameHeight(): number {
    return 4 * this.getInputFrameWidth();
  }

  public getInputFrameWidth(): number {
    let result: number = this.parent.sizeMap(this.data.FrameWidth);
    if (result === 0 && this.data.FrameWidth > 0) {
      result = 1;
    }

    return result;
  }
  public getInputInteriorBorder(): number {
    let result: number = this.parent.sizeMap(this.data.InputEdit.InteriorBorder);
    if (result === 0 && this.data.InputEdit.InteriorBorder > 0) {
      result = 1;
    }

    return result;
  }

  public getEditHeight(lines: number): number {
    return 2 * UFInteriorMargin + 2 * this.parent.sizeMap(this.data.InputEdit.InteriorBorder) + lines * this.InputFont.TextHeight;
  }
}

class VCXLabel extends VCXControl<VCXLabelBaseMetaData> {
  private font: VCXFontNoColor;

  public getHeight(lines: number): number {
    return this.Font.TextHeight * lines;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }
}

class VCXBookTab extends VCXControl<VCXTabControlMetaData> {
  private aFont: VCXFontNoColor;
  private acFont: VCXFontNoColor;

  public get AccessorFont(): VCXFontNoColor {
    if (!this.aFont) {
      this.aFont = new VCXFontNoColor(this.data.AccessorFont, this.parent);
    }

    return this.aFont;
  }

  public get CurrentAccessorFont(): VCXFontNoColor {
    if (!this.acFont) {
      this.acFont = new VCXFontNoColor(this.data.CurrentAccessorFont, this.parent);
    }

    return this.acFont;
  }
}

class VCXView extends VCXControl<VCXViewControlMetaData> {
  public get FrameWidth(): number {
    return this.parent.sizeMap(this.data.FrameWidth);
  }

  public get Margin(): number {
    return this.parent.sizeMap(this.data.Margin);
  }

  public get OutLineWidth(): number {
    return this.parent.sizeMap(this.data.OutLineWidth);
  }
}

export class RGB {
  r: number;
  g: number;
  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  public toHTML(opacity: number): string {
    return `rgba(${this.r},${this.g},${this.b},${opacity})`;
  }
}

export interface DecorateResult {
  backColor: string;
  foregroundColor: string;
  bold?: boolean;
}

export class VCXColorMap extends VCXControl<VCXColorMapMetaData> {
  getColor(key: number): string {
    return this.colorMapHTML(key);
  }

  getColorRGB(key: number): RGB {
    return this.colorMap(key);
  }

  // Ať nevytváříme více logiky převodu
  public static intToHex(value: number): string {
    return VCXColorMap.rgbToHex(VCXColorMap.intToRGB(value));
  }
  public static hexToInt(value: string): number {
    return VCXColorMap.RGBToInt(VCXColorMap.hexToRgb(value));
  }

  private colorMap(value: number): RGB {
    let result: RGB = VCXColorMap.intToRGB(value);

    if (this.data.LumaShift > 0) {
      result = VCXColorMap.shiftColorLuma(result, this.data.LumaShift);
    }

    if (this.data.Gray === true) {
      result = VCXColorMap.colorToGrayScale(result);
    }

    if (this.data.Alpha > 0) {
      result = VCXColorMap.combineAlpha(result, VCXColorMap.intToRGB(this.data.AlphaColor), this.data.Alpha);
    }

    return result;
  }

  private colorMapHTML(key: number): string {
    return VCXColorMap.rgbToHex(this.colorMap(key));
  }

  public static combineAlpha(value: RGB, alphaColor: RGB, alpha: number): RGB {
    const a: number = alpha / 255;

    return new RGB(
      Math.round(value.r + (alphaColor.r - value.r) * a),
      Math.round(value.g + (alphaColor.g - value.g) * a),
      Math.round(value.b + (alphaColor.b - value.b) * a)
    );
  }

  public static getDifferentColor(color: number, level: number) {
    const rgb = this.intToRGB(color);
    const hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    let result: RGB;

    if (hsl[2] > 50) {
      result = this.darkenColor(rgb, level);
    } else {
      result = this.lightenColor(rgb, level);
    }

    return this.RGBToInt(result);
  }

  public static darkenColor(color: RGB, level: number) {
    let result: RGB;

    switch (level) {
      case TColorShiftLevel.cslMin:
        result = this.shiftColorLuma(color, -63);
        break;
      case TColorShiftLevel.csl1:
        result = this.shiftColorLuma(color, -149);
        break;
      case TColorShiftLevel.csl2:
        result = this.shiftColorLuma(color, -235);
        break;
      case TColorShiftLevel.csl3:
        result = this.shiftColorLuma(color, -470);
        break;
      case TColorShiftLevel.cslMax:
        result = this.shiftColorLuma(color, -784);
        break;
      default:
        result = this.shiftColorLuma(color, -level);
        break;
    }

    return result;
  }

  public static lightenColor(color: RGB, level: number) {
    let result: RGB;

    switch (level) {
      case TColorShiftLevel.cslMin:
        result = this.shiftColorLuma(color, 118);
        break;
      case TColorShiftLevel.csl1:
        result = this.shiftColorLuma(color, 215);
        break;
      case TColorShiftLevel.csl2:
        result = this.shiftColorLuma(color, 333);
        break;
      case TColorShiftLevel.csl3:
        result = this.shiftColorLuma(color, 548);
        break;
      case TColorShiftLevel.cslMax:
        result = this.shiftColorLuma(color, 881);
        break;
      default:
        result = this.shiftColorLuma(color, level);
        break;
    }

    return result;
  }

  public static rgbToHsl(r: number, g: number, b: number) {
    (r /= 255), (g /= 255), (b /= 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;

    let h = 0,
      s = 0,
      l = 0;
    // Calculate hue
    // No difference
    if (delta == 0) h = 0;
    // Red is max
    else if (max == r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (max == g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (max + min) / 2;

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l];
  }

  public static shiftColorLuma(color: RGB, value: number): RGB {
    if (value > 1000) {
      value = 999;
    } else if (value < -1000) {
      value = -999;
    }

    const newColor = this.colorAdjustLuma(color, value);
    const result = new RGB(newColor.r, newColor.g, newColor.b);

    return result;
  }

  public static colorAdjustLuma(color: RGB, percent: number): RGB {
    const amt = Math.round(2.55 * percent),
      R = color.r + amt,
      B = color.b + amt,
      G = color.g + amt;

    const result = new RGB(R < 255 ? (R < 1 ? 0 : R) : 255, G < 255 ? (G < 1 ? 0 : G) : 255, B < 255 ? (B < 1 ? 0 : B) : 255);

    return result;
  }

  static hexToRgb(hex: string): RGB {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? new RGB(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)) : null;
  }

  private static componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  static rgbToHex(rgb: RGB): string {
    return "#" + this.componentToHex(rgb.r) + this.componentToHex(rgb.g) + this.componentToHex(rgb.b);
  }

  private static colorToGrayScale(color: RGB): RGB {
    const v = (color.r >> 2) + (color.g >> 1) + (color.b >> 2);
    return new RGB(v, v, v);
  }

  public static intToRGB(num: number): RGB {
    const r: number = num & 0xff;
    const g: number = (num >> 8) & 0xff;
    const b: number = (num >> 16) & 0xff;

    return new RGB(r, g, b);
  }

  public static RGBToInt(rgb: RGB): number {
    const result: number = rgb.r + (rgb.g << 8) + (rgb.b << 16);
    return result;
  }

  public getColorsForActionDecorate(decorate: DataActionDecorate, enabled: boolean): DecorateResult {
    if (decorate) {
      switch (decorate) {
        case DataActionDecorate.dadeBase:
          return { backColor: this.getColor(this.data.BaseColorBck1), foregroundColor: this.getColor(this.data.BaseColorFrg1) };
        case DataActionDecorate.dadeError:
          return { backColor: this.getColor(this.data.ErrorColorBck), foregroundColor: this.getColor(this.data.ErrorColorFrg) };
        case DataActionDecorate.dateHighLight:
          return { backColor: this.getColor(this.data.HighLightColorBck), foregroundColor: this.getColor(this.data.HighLightColorFrg) };
        case DataActionDecorate.dadeContent:
          return { backColor: this.getColor(this.data.ContentColorBck1), foregroundColor: this.getColor(this.data.ContentNormalColorFrg) };
        case DataActionDecorate.dadeContentSuppress:
          if (enabled) {
            return { backColor: this.getColor(this.data.ContentColorBck1), foregroundColor: this.getColor(this.data.ContentFrame2) }; // sedy, ale aktivni button (napr. den byvaleho mesice v kalendari)
          }
          return { backColor: this.getColor(this.data.AccentBaseColorBck), foregroundColor: this.getColor(this.data.AccentBaseColorFrg) }; // neaktivni button
        case DataActionDecorate.dadeTransparent:
          return { backColor: "rgba(0,0,0,0)", foregroundColor: this.getColor(this.data.BaseColorBck1) };
        case DataActionDecorate.dadeTransparentBold:
          return { backColor: "rgba(0,0,0,0)", foregroundColor: this.getColor(this.data.BaseColorBck1), bold: true };
      }
    }
    return { backColor: this.getColor(this.data.AccentBaseColorBck), foregroundColor: this.getColor(this.data.AccentBaseColorFrg) };
  }
}

class VCXDashboard extends VCXControl<VCXDashboardControlMetaData> {
  private tileFont: VCXFontNoColor;
  private tileSmallFont: VCXFontNoColor;
  private filterInnerFont: VCXFontNoColor;
  private simpleTableFont: VCXFontNoColor;
  private graphFont: VCXFontNoColor;

  public get TileFont(): VCXFontNoColor {
    if (!this.tileFont) {
      this.tileFont = new VCXFontNoColor(this.data.TileFont, this.parent);
    }

    return this.tileFont;
  }

  public get TileSmallFont(): VCXFontNoColor {
    if (!this.tileSmallFont) {
      this.tileSmallFont = new VCXFontNoColor(this.data.TileSmallFont, this.parent);
    }

    return this.tileSmallFont;
  }

  public get FilterInnerFont(): VCXFontNoColor {
    if (!this.filterInnerFont) {
      this.filterInnerFont = new VCXFontNoColor(this.data.FilterInnerFont, this.parent);
    }

    return this.filterInnerFont;
  }

  public get SimpleTableFont(): VCXFontNoColor {
    if (!this.simpleTableFont) {
      this.simpleTableFont = new VCXFontNoColor(this.data.SimpleTableFont, this.parent);
    }

    return this.simpleTableFont;
  }

  public get GraphFont(): VCXFontNoColor {
    if (!this.graphFont) {
      this.graphFont = new VCXFontNoColor(this.data.GraphFont, this.parent);
    }

    return this.graphFont;
  }
}

class VCXListView extends VCXControl<VCXListViewControlMetaData> {
  private font: VCXFontNoColor;

  public getHeight(lines: number): number {
    return this.Font.TextHeight * lines;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }

  public get SmallIconSize() {
    return this.parent.sizeMap(this.data.SmallIconSize);
  }

  public get LargeIconSize() {
    return this.parent.sizeMap(this.data.LargeIconSize);
  }
}

class VCXTreeView extends VCXControl<VCXTreeViewControlMetaData> {
  private font: VCXFontNoColor;
  private hFont: VCXFontNoColor;

  public getHeight(lines: number): number {
    return this.Font.TextHeight * lines;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }

  public get HeaderFont(): VCXFontNoColor {
    if (!this.hFont) {
      this.hFont = new VCXFontNoColor(this.data.HeadingFont, this.parent);
    }

    return this.hFont;
  }
}

class VCXTitle extends VCXControl<VCXTitleControlMetaData> {
  private font: VCXFontNoColor;

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }
}

class VCXSplitterControl extends VCXControl<VCXSplitterControlMetaData> {
  public get Size() {
    return this.parent.sizeMap(this.data.Size);
  }
}
