import { ColumnProportionEm, OrderByItem, TAlignment } from "../../common/communication.base";
import { WithVCXProps } from "../k2hoc";

type ColumnClick = (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean, ctrlKey: boolean) => void;
type ColumnContextMenu = (rowIndex: number, colIndex: number) => void;
type ColumnResize = (colIndex: number, diffX: number, isCompleted: boolean) => void;
export type FixedColumnOffset = (columnsWidths: number[], index: number, fixed: boolean) => number;

export interface ColumnsProps {
  columnsProportion: Array<ColumnProportionEm>;
  columnsWidths: number[];
  columnsVersion: number;
  fixedColumnsCount: number;
}

export interface DGHeaderProps extends WithVCXProps, ColumnsProps {
  isDraggable?: boolean;
  isResizable?: boolean;
  onColumnContextMenu: ColumnContextMenu;
  onColumnClick: ColumnClick;
  onColumnResize?: ColumnResize;
  setColumns?: (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => void;
  deleteColumn?: () => void;
  getFixedColumnOffset: FixedColumnOffset;
  editingColumns?: (editing: boolean) => boolean;
}

interface BaseColumnProps extends WithVCXProps {
  columnProportion: ColumnProportionEm;
  onColumnContextMenu: ColumnContextMenu;
  onColumnClick: ColumnClick;
  fixed: boolean;
  lastColumn?: boolean;
  isTreeCell?: boolean;
  columnOffset?: number;
  columnNdx: number;
  rowHeightMultiplier?: number;
}

export interface HeaderColumnProps extends BaseColumnProps {
  isDraggable?: boolean;
  isResizable?: boolean;
  orderBy?: number;
  orderByCount?: number;
  onColumnResize?: ColumnResize;
  setColumns?: (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => void;
  deleteColumn?: () => void;
  editingColumns?: (editing: boolean) => boolean;
}

export function compareArray(array: Array<any>, newArray: Array<any>) {
  if (array) {
    if (newArray) {
      if (array.length === newArray.length) {
        return true;
      }
    }
  }

  return false;
}

export function compareNumberArray(array: Array<number>, newArray: Array<number>) {
  if (compareArray(array, newArray)) {
    let result = true;
    array.forEach((value, index) => {
      if (value !== newArray[index]) {
        result = false;
        return;
      }
    });
    return result;
  }
  return false;
}

export function getTextAlign(align: TAlignment): "center" | "left" | "right" {
  switch (align) {
    case TAlignment.taCenter:
      return "center";
    case TAlignment.taLeftJustify:
      return "left";
    case TAlignment.taRightJustify:
      return "right";
  }
}

export function getContentAlign(align: TAlignment): "center" | "flex-start" | "flex-end" {
  switch (align) {
    case TAlignment.taCenter:
      return "center";
    case TAlignment.taLeftJustify:
      return "flex-start";
    case TAlignment.taRightJustify:
      return "flex-end";
  }
}

export function getFixedColumnStyles(width: number, offset: number) {
  return {
    width: width,
    left: `${offset}px`,
  };
}
